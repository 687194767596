<template>
    <div class="block">
        <img src="@/assets/img/404.png" alt="">
        <div class="text">抱歉~您访问的页面不存在</div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
.block{width: 100%;text-align: center;padding-top: 30%;}
    img{width: 260px;height: 260px;margin: auto;display: block;}
    .text{color: #1a1a1a;margin-top: 40px;}
</style>